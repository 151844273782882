import { join, serialize } from '@pepita/querystring';
import type {
  CLSAttribution,
  CLSMetric,
  FCPAttribution,
  FCPMetric,
  INPAttribution,
  INPMetricWithAttribution,
  LCPAttribution,
  LCPMetric,
  LoadState,
  Metric,
  TTFBAttribution,
  TTFBMetric,
} from 'web-vitals/attribution';

import { isFeatureEnabled } from 'src/config/features-toggle';

const MAX_INP_VALUE = 30000;

let isLoaded = false;

type GTMConfig = {
  id: string;
  auth?: string;
  preview?: string;
};

export type GTMDataProps = Record<string, any>;

if (typeof window !== 'undefined') {
  (window as any).dataLayer = (window as any).dataLayer || [];
}

export const loadGTM = (config: GTMConfig) => {
  if (typeof window === 'undefined') return Promise.resolve();

  const GTM_SERVER_URL = isFeatureEnabled('GTM_SERVER')
    ? 'https://cgapigw.immobiliare.it'
    : 'https://www.googletagmanager.com';

  return new Promise((resolve) => {
    if (isLoaded) resolve(undefined);
    else {
      if (config && config.id) {
        (window as any).dataLayer.push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });

        const firtsScriptElement = document.getElementsByTagName('script')[0];
        const tagManagerScriptElement = document.createElement('script');

        (tagManagerScriptElement as any).async = true;
        (tagManagerScriptElement as any).src = join(
          `${GTM_SERVER_URL}/gtm.js`,
          serialize({
            id: config.id,
            gtm_auth: config.auth,
            gtm_preview: config.preview,
          })
        );
        firtsScriptElement.parentNode?.insertBefore(
          tagManagerScriptElement,
          firtsScriptElement
        );

        tagManagerScriptElement.onload = () => {
          resolve(undefined);
        };

        isLoaded = true;
      }
    }
  });
};

const delay =
  // @ts-ignore
  typeof requestIdleCallback !== 'undefined'
    ? // @ts-ignore
      requestIdleCallback
    : (fn) => requestAnimationFrame(fn);

export const sendDataToGTM = (data: GTMDataProps) => {
  if (typeof window !== 'undefined') {
    delay(() => {
      requestAnimationFrame(() => {
        (window as any).dataLayer.push(data);
      });
    });
  }
};

/**
 * Get device user agent and connection type.
 */
const getDeviceInfo = () => {
  if (typeof navigator !== 'undefined') {
    return {
      userAgent: navigator.userAgent,
      connection: (navigator as NavigatorNetworkInformation).connection
        ?.effectiveType,
    };
  }

  return {};
};

/**
 * Iterates through long animation frame entries and scripts to find the longest script duration
 * and returns a custom LoAF object
 */
const createLoAFObject = (attribution: INPAttribution) => {
  const {
    inputDelay,
    processingDuration,
    presentationDelay,
    longAnimationFrameEntries,
  } = attribution;
  let longestScript: PerformanceScriptTiming = {
    duration: 0,
    sourceURL: '',
    invoker: '',
    invokerType: '',
  };

  for (const entry of longAnimationFrameEntries) {
    for (const script of entry.scripts) {
      if (script.duration > longestScript.duration) {
        longestScript = script;
      }
    }
  }

  return {
    loafDuration: longestScript.duration,
    loafSourceURL: longestScript.sourceURL,
    loafInvoker: longestScript.invoker,
    loafInvokerType: longestScript.invokerType,
    inputDelay,
    processingDuration,
    presentationDelay,
  };
};

type AttributionObject = {
  debugTarget?: string;
  debugEvent?: INPAttribution['interactionType'] | '';
  debugTiming?: LoadState | '';
  eventTime?: string | number;
};

/**
 * Creates a custom AttributionObject based on the provided attribution properties.
 * Assigns default empty strings for any missing properties.
 */
const createAttributionObject = ({
  debugTarget = '',
  debugEvent = '',
  debugTiming = '',
  eventTime = '',
}: AttributionObject) => ({
  debugTarget,
  debugEvent,
  debugTiming,
  eventTime,
});

const getCLSAttribution = (attribution: CLSAttribution) =>
  createAttributionObject({
    debugTarget: attribution.largestShiftTarget,
    debugTiming: attribution.loadState,
    eventTime: attribution.largestShiftTime,
  });

const getLCPAttribution = (attribution: LCPAttribution) =>
  createAttributionObject({
    debugTarget: attribution.element,
    eventTime: attribution.lcpEntry?.startTime,
  });

const getFCPAttribution = (attribution: FCPAttribution) =>
  createAttributionObject({
    debugTiming: attribution.loadState,
  });

const getINPAttribution = (attribution: INPAttribution) => ({
  ...createAttributionObject({
    debugTarget: attribution.interactionTarget,
    debugEvent: attribution.interactionType,
    debugTiming: attribution.loadState,
    eventTime: attribution.interactionTime,
  }),
  ...createLoAFObject(attribution),
  ...getDeviceInfo(),
  elementOuterHTML: (
    attribution.interactionTargetElement as HTMLElement | undefined
  )?.outerHTML,
});

/**
 * Retrieves attribution properties based on the provided web-vital name.
 */
const getAttributionProperties = (
  name: Metric['name'],
  attribution:
    | CLSAttribution
    | LCPAttribution
    | FCPAttribution
    | TTFBAttribution
    | INPAttribution
) => {
  const getters = {
    CLS: getCLSAttribution,
    LCP: getLCPAttribution,
    FCP: getFCPAttribution,
    INP: getINPAttribution,
  };

  return getters[name]?.(attribution) || createAttributionObject({});
};

const isINPMetricWithAttribution = (
  metric:
    | (CLSMetric | LCPMetric | FCPMetric | TTFBMetric)
    | INPMetricWithAttribution
): metric is INPMetricWithAttribution =>
  typeof metric === 'object' && 'attribution' in metric;

export const sendToDataLayerWebVitals = (
  metric:
    | (CLSMetric | LCPMetric | FCPMetric | TTFBMetric)
    | INPMetricWithAttribution
) => {
  const { rating, name, id, value, delta } = metric;

  if (name === 'INP' && value > MAX_INP_VALUE) return;

  const attribution = isINPMetricWithAttribution(metric)
    ? metric.attribution
    : undefined;

  const webVitalsMeasurement = {
    name,
    id,
    value,
    delta,
    rating,
    valueRounded: Math.round(name === 'CLS' ? value * 1000 : value),
    deltaRounded: Math.round(name === 'CLS' ? delta * 1000 : delta),
    ...(attribution && getAttributionProperties(name, attribution)),
  };

  (window as any).dataLayer.push({
    event: 'coreWebVitals',
    webVitalsMeasurement,
  });
};
