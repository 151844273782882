import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { useSendGA4Event } from 'src/tracking/ga/hooks/useSendGAEvent';
import { useTrack } from 'src/tracking/hooks/useTrack';
import { hashEmail, hashPhone } from 'src/tracking/utils';
import { EventName } from 'src/tracking/utils/enums';
import { GATrackingEvent } from 'src/tracking/utils/enums';
import { serviceToAuthMethod } from '../tracking/utils';

export const useGoogleOneTapTracking = () => {
  const track = useTrack();
  const sendGA4Event = useSendGA4Event();
  const { locale } = useRouter();
  const trackLoginComplete = useCallback(
    (
      service: string,
      uuid: string | null,
      email: string | null,
      phone: string | null,
      loginType: 'trackSSORegistration' | 'trackSSOAccess'
    ) => {
      track(EventName.LOGIN_COMPLETE, {
        'Log In Method': serviceToAuthMethod.get(service),
      });
      sendGA4Event(
        loginType === 'trackSSORegistration'
          ? GATrackingEvent.USER_SIGNIN
          : GATrackingEvent.USER_LOGIN,
        {
          language: locale,
          medium: 'google_onetap',
          user_id: uuid,
          user_hashedemail: email ? hashEmail(email) : undefined,
          user_hashedphone: phone ? hashPhone(phone) : undefined,
        }
      );
    },
    [locale, sendGA4Event, track]
  );

  const trackLoginFailed = useCallback(
    (service: string) => {
      track(EventName.LOGIN_FAILED, {
        'Log In Method': serviceToAuthMethod.get(service),
      });
    },
    [track]
  );

  const trackLoginStart = useCallback(
    (service: string) => {
      track(EventName.LOGIN_START, {
        'Log In Method': serviceToAuthMethod.get(service),
      });
    },
    [track]
  );

  return {
    trackLoginComplete,
    trackLoginFailed,
    trackLoginStart,
  };
};
