import { useCallback } from 'react';
import { sendDataToGTM } from '@pepita-fe/gtm';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';

import { isBotAtom } from 'src/atoms/isBotAtom';

import { isFeatureEnabled } from 'src/config/features-toggle';

import type { GATrackingEvent } from 'src/tracking/utils/enums';

import { getGA4SectionName, getGA4UserTrackingData } from '../utils/store';

export const useSendGA4Event = () => {
  const isBot = useAtomValue(isBotAtom);
  const { locale } = useRouter();
  const sendGA4Event = useCallback(
    <T extends Record<string, unknown>>(
      eventName?: GATrackingEvent,
      trackingData?: T
    ) => {
      const userTrackingData = getGA4UserTrackingData();
      const sectionName = trackingData?.section_name ?? getGA4SectionName();

      if (
        userTrackingData === undefined ||
        (isFeatureEnabled('PREVENT_BOT_TRACKING') && isBot) ||
        isBot === undefined
      ) {
        return;
      }

      sendDataToGTM({
        event: eventName,
        language: locale,
        ...userTrackingData,
        ...trackingData,
        section_name: sectionName,
      });
    },
    [isBot, locale]
  );

  return sendGA4Event;
};
