import { useEffect } from 'react';
import { sendToDataLayerWebVitals } from '@pepita-fe/gtm';
import { onCLS, onFCP, onLCP, onTTFB } from 'web-vitals';
import { onINP } from 'web-vitals/attribution';

export const useWebVitals = () => {
  useEffect(() => {
    onCLS(sendToDataLayerWebVitals);

    onLCP(sendToDataLayerWebVitals);

    onFCP(sendToDataLayerWebVitals);

    onTTFB(sendToDataLayerWebVitals);

    onINP(sendToDataLayerWebVitals);
  }, []);
};
