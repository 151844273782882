import type { CATEGORY } from 'src/constants/real-estate';
import type { CONTRACT, TYPOLOGIES } from 'src/constants/typologies';

import type { Language } from './translations';

export interface HomepageResponse {
  statusCode: number;
  redirectUrl: Nullable<string>;
  data: Nullable<HomepageData>;
}

export type HomepageData = {
  seoMap?: SeoMap;
  seoSiteMap?: SeoSiteMap;
  seoMeta: SeoMeta;
  pageContent: PageContent;
  pageLookup: PageLookup;
};

export type PageLookup = {
  associations: AssociationLookupItem[];
  franchising: FranchisingLookupItem[];
};

export type AssociationLookupItem = {
  id: string;
  value: string;
};

export type FranchisingLookupItem = {
  id: string;
  value: string;
};

export type SeoMeta = {
  title?: string;
  subtitle?: string;
  keywords?: string;
  canonical?: string;
  alternate?: { rel: 'alternate'; hreflang: Language; href: string }[];
};

export type PageContent = {
  headline: Headline;
  categoryId: CATEGORY;
  contractId: CONTRACT;
  isAlternate: boolean;
  typologyId: Nullable<TYPOLOGIES>;
  provinceId: Nullable<string>;
  localsMin: Nullable<number>;
  localsMax: Nullable<number>;
};

export type Headline = {
  text: string;
  url: Nullable<string>;
};

export type SeoMap = {
  title: string;
  description: string;
  franchising?: Franchising;
  associations?: Associations;
  rows: SeoMapRow[][];
  items?: SeoMapItem[];
  panels?: SeoMapPanel[];
};

export type SeoMapRow = {
  active: boolean;
  text: string;
  link: string;
  attr: {
    title: string;
    'data-target'?: string;
  };
};

export type SeoMapItem = {
  text: string;
  id: string;
  meta: string;
  link?: string;
  attr: {
    title: string;
  };
  items?: {
    text: string;
    link: string;
  }[];
  nation?: string;
  region?: string;
};

export type SeoMapPanel = {
  title: string;
  link: Nullable<string>;
  items: SeoMapItem[];
  linkTitle: string;
};

export interface Franchising {
  title: string;
  text: string[];
  items: FranchisingItem[];
}

export type FranchisingItem = {
  title: string;
  text: string;
  image: string;
  url: string;
};

export interface Associations {
  items: AssociationItem[];
}

export type AssociationItem = {
  title: string;
  text: string;
  image: string;
  url: string;
};

export type SeoSiteMap = {
  title: string;
  description: string;
  indexes: SeoSiteMapIndexes;
  related: Nullable<SeoSiteMapRelated>;
  neighboursTouristicAreas: Nullable<SeoSiteMapNeighboursTouristicAreas>;
};

export type SeoSiteMapIndexes = {
  A?: { items: SeoSiteMapIndexesItem[] };
  B?: { items: SeoSiteMapIndexesItem[] };
  C?: { items: SeoSiteMapIndexesItem[] };
  D?: { items: SeoSiteMapIndexesItem[] };
  E?: { items: SeoSiteMapIndexesItem[] };
  F?: { items: SeoSiteMapIndexesItem[] };
  G?: { items: SeoSiteMapIndexesItem[] };
  H?: { items: SeoSiteMapIndexesItem[] };
  I?: { items: SeoSiteMapIndexesItem[] };
  J?: { items: SeoSiteMapIndexesItem[] };
  K?: { items: SeoSiteMapIndexesItem[] };
  L?: { items: SeoSiteMapIndexesItem[] };
  M?: { items: SeoSiteMapIndexesItem[] };
  N?: { items: SeoSiteMapIndexesItem[] };
  O?: { items: SeoSiteMapIndexesItem[] };
  P?: { items: SeoSiteMapIndexesItem[] };
  Q?: { items: SeoSiteMapIndexesItem[] };
  R?: { items: SeoSiteMapIndexesItem[] };
  S?: { items: SeoSiteMapIndexesItem[] };
  T?: { items: SeoSiteMapIndexesItem[] };
  U?: { items: SeoSiteMapIndexesItem[] };
  V?: { items: SeoSiteMapIndexesItem[] };
  W?: { items: SeoSiteMapIndexesItem[] };
  X?: { items: SeoSiteMapIndexesItem[] };
  Y?: { items: SeoSiteMapIndexesItem[] };
  Z?: { items: SeoSiteMapIndexesItem[] };
};

export type SeoSiteMapIndexesItem = {
  text: string;
  meta: number;
  link: string;
  items: SeoSiteMapIndexesChildrenItem[];
  highlighted: boolean;
};

export type SeoSiteMapIndexesChildrenItem = {
  text: string;
  link: string;
};

export type SeoSiteMapRelated = {
  title: string;
  items: SeoSiteMapRelatedItem[];
};

export type SeoSiteMapRelatedItem = {
  text: string;
  link: { url: string };
};

export type SeoSiteMapNeighboursTouristicAreas = {
  title: string;
  items: SeoSiteMapNeighboursTouristicAreasItem[];
};

export type SeoSiteMapNeighboursTouristicAreasItem = {
  text: string;
  link: { url: string };
};

export interface Discover {
  items: DiscoverItem[];
}

export enum DISCOVER_ITEM_TYPES {
  EVALUATION_BUILDING = 'valutazione-immobiliare',
  CITYGUIDE = 'mercato-immobiliare',
  PUBLISH_AD = 'pubblica-annuncio',
  DRAW_ON_MAP = 'map-draw',
  MORTGAGE = 'mortgage',
  MYHOME = 'my-home',
}

export type DiscoverItem = {
  title: string;
  text: string;
  action: string;
  image: string;
  url?: string;
  externalUrl?: boolean;
  onClick?: () => void;
  type: DISCOVER_ITEM_TYPES;
  isNew?: boolean;
};
